import React from 'react';

export type Tlist = {
    en: string[];
    tr: string[];
};
export const list: Tlist = {
    en: [
        'raise public awareness about the expected large earthquake in the Marmara region.',
        'enable informed decision-making for the inhabitants, and',
        'support them during earthquake precaution preparations.'
    ],

    tr: [
        'bölge halkını  bilinçlendirmek,',
        'bilgiye dayalı karar verebilme yetkinliği sunmak ve',
        'depreme hazırlık aşamalarında destek olmak'
    ]
};

export const setTitle = (lang: string) => {
    switch (lang) {
        case 'en':
            return 'About';
        case 'tr':
            return 'Hakkımızda';
        default:
            return 'About';
    }
};
export const subTitle = (lang: string) => {
    switch (lang) {
        case 'en':
            return <p>Magnus is designed and developed to</p>;
        case 'tr':
            return (
                <p>
                    Magnus, Marmara denizinde önümüzde yıllarda gerçekleşmesi beklenen büyük deprem
                    tehlikesine karşı,
                </p>
            );

        default:
            return <p>Magnus is designed and developed to</p>;
    }
};
export const setList = (lang: string): string[] => {
    switch (lang) {
        case 'en':
            return list.en;
        case 'tr':
            return list.tr;
        default:
            return list.en;
    }
};
export const setSubTitle = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <span>
                    Magnus is a non-commercial, ad-free mobile application that does not require any
                    membership and does not store any information about its users on its servers.
                </span>
            );
        case 'tr':
            return (
                <span>
                    amacıyla geliştirilmiştir. Magnus üyelik gerektirmeyen ücretsiz ve reklamsız bir
                    uygulamadır. Kullanıcıları hakkında hiçbir bilgi toplamaz ve sunucularında
                    barındırmaz.
                </span>
            );
        default:
            return (
                <span>
                    Magnus is a non-commercial, ad-free mobile application that does not require any
                    membership and does not store any information about its users on its servers.
                </span>
            );
    }
};

export const setBrandText = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <div className="mt-4">
                    <p className="font-light text-xs 2xl:text-base mt-2">
                        Magnus is a product of Wunder Innovation Studio. For more information,
                        please visit{' '}
                        <a style={{ color: '#2E7FFA' }} href="https://wunder.is">
                            https://wunder.is
                        </a>
                        .
                    </p>
                </div>
            );
        case 'tr':
            return (
                <div className="mt-4">
                    <p className="font-light text-xs 2xl:text-base mt-2">
                        Magnus, bir Wunder Innovation Studio ürünüdür. Daha fazla bilgi için lütfen
                        <a style={{ color: '#2E7FFA' }} href=" https://wunder.is">
                            {' '}
                            https://wunder.is
                        </a>{' '}
                        adresini ziyaret ediniz.
                    </p>
                </div>
            );
        default:
            break;
    }
};
