import React from 'react';
import { useLanguage } from '../../store/store';
import './Footer.scss';
import { setAbout, setContact, setHome } from '../NavigationBar/utils';
import Logo from '../../assets/svg/LogoWhite.svg';
import StoreButton from '../StoreButton/StoreButton';
import { setCompany, setSocial } from './utils';
import QR from '../../assets/svg/QR.svg';

export interface FooterProps {
    label?: string;
}

const Footer = ({ label }: FooterProps) => {
    const { selectedLanguage } = useLanguage();

    return (
        <div className="Footer" data-testid="Footer">
            <div className="Footer__logo">
                <div className="flex">
                    <img src={Logo} alt="logo" />
                </div>
                <div className="storebutton-mobile">
                    <StoreButton></StoreButton>
                </div>
                <div className="Footer__rights__desktop">
                    <span>©2022 Magnus. All Rights Reserved.</span>
                </div>
            </div>
            <div className="Footer__links">
                <div className="Footer__links__company">
                    <span className="font-bold link-title">{setCompany(selectedLanguage)}</span>
                    <div className="flex flex-col">
                        <a className="font-light link-subtitle" href="/">
                            {setHome(selectedLanguage)}
                        </a>
                        <a className="font-light link-subtitle" href="/about">
                            {setAbout(selectedLanguage)}
                        </a>
                        <a className="font-light link-subtitle" href="mailto:info@magnus.systems">
                            {setContact(selectedLanguage)}
                        </a>
                    </div>
                </div>
                <div className="Footer__links__social">
                    <span className="font-bold link-title">{setSocial(selectedLanguage)}</span>
                    <div className="flex social-container">
                        <div className="flex flex-col">
                            <a
                                rel="noreferrer"
                                target="_blank"
                                className="font-light link-subtitle"
                                href="https://www.instagram.com/magnus_systems/"
                            >
                                Instagram
                            </a>

                            <a
                                className="font-light link-subtitle"
                                rel="noreferrer"
                                target="_blank"
                                href="https://twitter.com/magnus_systems"
                            >
                                Twitter
                            </a>
                        </div>
                        <div className="flex flex-col">
                            <a
                                rel="noreferrer"
                                target="_blank"
                                className="font-light link-subtitle"
                                href="https://www.linkedin.com/company/magnus-systems"
                            >
                                LinkedIn
                            </a>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                className="font-light link-subtitle"
                                href="https://www.youtube.com/channel/UCNF8HdOiKlImERE7pQNx5xQ?view_as=subscriber"
                            >
                                Youtube
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="storebutton-desktop">
                <span className="download-title">Download</span>
                <div className="Footer__qr">
                    <img src={QR} alt="" />
                </div>
            </div>
            <div className="Footer__rights__mobile">
                <span>©2022 Magnus. All Rights Reserved.</span>
            </div>
        </div>
    );
};

export default Footer;
