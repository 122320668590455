import React, { useEffect } from 'react';
import './DirectPage.scss';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../store/store';
import Logo from '../../assets/svg/Logo.svg';
import { setText } from '../DownloadPage/utils';

const DirectPage = () => {
    const location = useLocation();
    const { selectedLanguage } = useLanguage();
    const user = window.navigator.userAgent;
    const getPlatform = (user: string) => {
        return user.toLowerCase().includes('android') ? 'android' : 'iphone';
    };

    const redirectToStore = (platform: string) => {
        if (platform === 'android') {
            window.open(
                'https://play.google.com/store/apps/details?id=is.wunder.quakeguard',
                '_top'
            );
        } else {
            console.log(platform, 'app sore');
            window.location.href = 'https://apps.apple.com/us/app/magnus/id1488116106';
        }
    };

    useEffect(() => {
        const platform = getPlatform(user);
        redirectToStore(platform);
    }, [location]);

    return (
        <div className="DirectPage" data-testid="DirectPage">
            <div className="DirectPage__logo">
                <img src={Logo} alt="magnus" />
            </div>
            <div className="DirectPage__text">{setText(selectedLanguage)}</div>
        </div>
    );
};

export default DirectPage;
