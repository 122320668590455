import React from 'react';
import { useLanguage } from '../../store/store';
import './ImageAndContent.scss';
import { setTitle1, setParag1, setTitle2, setParag2, setTitle3, setParag3 } from './utils';
import Image1 from '../../assets/png/Group 39 1.png';
import Image2 from '../../assets/png/Group 64.png';
import AnimatedContent from './AnimatedContent';
import Lottie from 'react-lottie';
import EarthquakeLottie from '../../assets/lottie/LastEarthquakes.json';
import AreasLottie from '../../assets/lottie/GatheringAreas.json';
import PrepListLottie from '../../assets/lottie/MagnusPrepList.json';
import LottieElement from '../LottieElement/LottieElement';

interface IBlock {
    title: string | JSX.Element;
    parag: string | JSX.Element;
    image: string;
    type: 'image-first' | 'content-first';
    lottieData: any;
}

const ImageAndContent = () => {
    const { selectedLanguage } = useLanguage();
    const blocks: IBlock[] = [
        {
            title: setTitle1(selectedLanguage),
            parag: setParag1(selectedLanguage),
            image: Image1,
            type: 'image-first',
            lottieData: AreasLottie
        },
        {
            title: setTitle2(selectedLanguage),
            parag: setParag2(selectedLanguage),
            image: Image2,
            type: 'content-first',
            lottieData: EarthquakeLottie
        },
        {
            title: setTitle3(selectedLanguage),
            parag: setParag3(selectedLanguage),
            image: Image1,
            type: 'image-first',
            lottieData: PrepListLottie
        }
    ];

    return (
        <div className="ImageAndContent" data-testid="ImageAndContent">
            {blocks.map((element, index) => {
                return (
                    <div className={`ImageAndContent__block ${element.type}`} key={index}>
                        <div className="ImageAndContent__content">
                            <div className="ImageAndContent__content__header">
                                <AnimatedContent
                                    type="title"
                                    title={element.title}
                                ></AnimatedContent>
                            </div>
                            <div className="ImageAndContent__content__parag">
                                <AnimatedContent
                                    type="parag"
                                    parag={element.parag}
                                ></AnimatedContent>
                            </div>
                        </div>
                        <div className="ImageAndContent__image">
                            <LottieElement
                                index={index}
                                bgImage={''}
                                lottieData={element.lottieData}
                            ></LottieElement>
                            {/* <img src={element.image} alt="img" /> */}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ImageAndContent;
