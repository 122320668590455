const privacyPolicy = {
    en: {
        time: 1663767993599,
        blocks: [
            {
                id: 'XoxPmWnA3M',
                type: 'header',
                data: {
                    text: 'MOBILE APPLICATION USER AGREEMENT',
                    level: 2
                }
            },
            {
                id: '8p8EbZbAj2',
                type: 'header',
                data: {
                    text: 'ARTICLE 1 – PARTIES',
                    level: 3
                }
            },
            {
                id: '3fj6M8p7AR',
                type: 'paragraph',
                data: {
                    text: 'This agreement and its annexes (ANNEX-1: Privacy Policy) is made by and between “Magnus” (hereinafter to be referred to as “Magnus”) and the User who will use the mobile application on the terms and conditions set forth in this Agreement.'
                }
            },
            {
                id: 'h1WD0LwKwM',
                type: 'header',
                data: {
                    text: 'ARTICLE 2 – DEFINITIONS',
                    level: 3
                }
            },
            {
                id: 'fNApl6cQcO',
                type: 'paragraph',
                data: {
                    text: '2.1. Application: The mobile application named Magnus means the mobile application software operating on all mobile devices and all virtual reality channels through which the products and services developed in connection with that software are offered. (hereinafter to be referred to as “Application”)'
                }
            },
            {
                id: 'uW5MqZa72W',
                type: 'paragraph',
                data: {
                    text: '2.2. User: Persons using the services provided by Magnus on the Application on the terms and conditions stipulated in this agreement (hereinafter to be referred to as “User”)'
                }
            },
            {
                id: 's9S3_MN1bP',
                type: 'paragraph',
                data: {
                    text: '2.3. System Access Tools: The management page for the User’s account and information necessary to access the application but only known by the User such as user name, password, code etc.'
                }
            },
            {
                id: 'BkA8pRIHj-',
                type: 'paragraph',
                data: {
                    text: '2.4. Communication Channels: Communication media such as instant notice, e-mail, sms, mms, phone etc.'
                }
            },
            {
                id: '9nimjNOaDZ',
                type: 'paragraph',
                data: {
                    text: '2.5. Message:&nbsp;&nbsp;Those messages sent by Magnus to the Users for the purposes of marketing, surveying, information giving etc. which are permitted by the Users to be sent by Magnus.'
                }
            },
            {
                id: 'hrlnX0j81a',
                type: 'paragraph',
                data: {
                    text: '2.6. User Account Management Page: The user specific web page accessed by the User only with the use of system access tools and used to perform transactions relating to his/her membership (hereinafter to be referred to as “Portal”).'
                }
            },
            {
                id: 'vPQ3IM7NB6',
                type: 'paragraph',
                data: {
                    text: '2.7 Payment Processor: Third person payment infrastructure suitable for the User’s device which will be used by Magnus in order to collect the fee payable for the application. These are named as Apple Pay or Google Pay.'
                }
            },
            {
                id: 'IzTWwd46-B',
                type: 'header',
                data: {
                    text: 'ARTICLE 3 – SUBJECT MATTER AND SCOPE OF THE AGREEMENT',
                    level: 3
                }
            },
            {
                id: 'E5CLOWYQ6u',
                type: 'paragraph',
                data: {
                    text: '3.1. This Agreement determines the terms of use for the Application and fix the rights and obligations of the User and Magnus.'
                }
            },
            {
                id: 'GsMXzmqy5W',
                type: 'paragraph',
                data: {
                    text: '3.2. With the acceptance of the terms and conditions stipulated in this agreement, the User will be deemed to have agreed on all statements made by Magnus in connection with the utilization, membership and services arising from the Application. The User agrees, represents and undertakes to act in compliance with those statements.'
                }
            },
            {
                id: 'YcdcqCcDlo',
                type: 'header',
                data: {
                    text: 'ARTICLE 4 - FILING THE APPLICATION AND MEMBERSHIP',
                    level: 3
                }
            },
            {
                id: '9rg1ugioub',
                type: 'paragraph',
                data: {
                    text: '4.1 The User agrees and undertakes that he/she will be entitled to become a User under this Agreement once he/she downloads the Application, completes the registration process and approves the notice that ”I have read and discussed the User Agreement and I hereby acknowledge its terms and conditions”. If it is determined by Magnus that the User did not observe this process, his/her agreement will be terminated by Magnus immediately.'
                }
            },
            {
                id: 'iJR__XPJJD',
                type: 'paragraph',
                data: {
                    text: '4.2 The User agrees that all information he/she had given during the application phase is and will be true, accurate, up-to-date and complete by all means. The User will be held responsible for any inaccurate and incomplete information and for the consequences arising from misleading information given by the User. The User is at all times entitled to update his/her personal information through the Application.&nbsp;'
                }
            },
            {
                id: '1nFQItBdu1',
                type: 'paragraph',
                data: {
                    text: '4.3 Monthly price payable for the Application shall be collected on monthly basis from the credit card registered with the application center suitable for the User’s device.'
                }
            },
            {
                id: 'HrzR9Vqypr',
                type: 'header',
                data: {
                    text: 'ARTICLE 5 - DESCRIPTION OF THE SERVICE',
                    level: 3
                }
            },
            {
                id: 'eG_HEVBOmV',
                type: 'paragraph',
                data: {
                    text: '5.1 “Magnus” Application is an application offering visual, audio and written information to the Users in order to help them in becoming more conscious of earthquakes, alarming them of any seismic motion and assisting them to be prepared for earthquakes with the supply for ground details and alarm service. However the Application does not provide any foresight or early warning about a potential earthquake. Therefore, Magnus cannot be held responsible for any decision and action taken by the Users based on the earthquake data and location details they saw on the screens of the Application.'
                }
            },
            {
                id: 'WGiETxTIpu',
                type: 'header',
                data: {
                    text: 'ARTICLE 6 - RIGHTS AND OBLIGATIONS OF THE USER',
                    level: 3
                }
            },
            {
                id: 'WkwptuzQN6',
                type: 'paragraph',
                data: {
                    text: '6.1 The User agrees that he/she will act in accordance with the conditions of this agreement and with the applicable legislation while he/she implements the user procedures, uses the products and services offered by the Application and performs any transaction in connection with the products and services available in the Application and that he/she has read, understood and hereby approves all of these conditions and rules.'
                }
            },
            {
                id: '_tTz6UL49D',
                type: 'paragraph',
                data: {
                    text: '6.2 The User will be entitled to use the contents and data available in the Application only after he/she downloads the Application and completes the membership process and becomes a subscriber as required in Article 4 herein.'
                }
            },
            {
                id: 'gpPXSdpDeo',
                type: 'paragraph',
                data: {
                    text: '6.3 When the User downloads the Application, he/she declares and agrees that he/she is older than 18. If the User is younger than 18 years, it will be deemed that his/her guardian or parent has granted explicit consent to this agreement.'
                }
            },
            {
                id: '1ig9sCb_-2',
                type: 'paragraph',
                data: {
                    text: '6.4 The User agrees that this Application belongs to and is operated by Magnus. Contents of this Application are protected according to the law governing intellectual and industrial rights. The User agrees and undertakes that he/she will not reproduce, copy, distribute or process any of the pictures, texts, visual or audio materials, video clips, files, database, catalogues or lists available in the Application if the same infringes the in-kind or personal rights or assets of Magnus and/or of a third person.&nbsp; &nbsp;'
                }
            },
            {
                id: 'hvZc0N7dY6',
                type: 'paragraph',
                data: {
                    text: '6.5 While using the Application, the User agrees to comply with the provisions of the Turkish Criminal Code, Turkish Commercial Code, Code of Obligations, Law No 5651 on the Law on Online Publications and Fighting Against the Crimes Committed through these Publications, Law on the Intellectual and Artistic Works, Law on the Protection of Personal Data, Industrial Property Law and all other applicable legislation and notices to be published by Magnus in connection with the Application.'
                }
            },
            {
                id: 'Cl9ama1d7U',
                type: 'paragraph',
                data: {
                    text: '6.6 Magnus shall not be held responsible for the contents published by the third persons including the User in the Application and in the internet sites whose links are given in the Application. The warranty about the accuracy and legality of the information, contents, visual and audio images provided or published by any third person shall be subject to the responsibility of that third person. Thus, Magnus does not provide any undertaking or warranty on the reliability, accuracy and legality of the services and contents provided by the third parties.'
                }
            },
            {
                id: 'DGyMtWHmu7',
                type: 'paragraph',
                data: {
                    text: '6.7 The User and Magnus are independent parties. Approval and performance of this agreement shall not create a relationship of partnership, representation or employer-employee between the parties.'
                }
            },
            {
                id: 'Anp89pqyKS',
                type: 'header',
                data: {
                    text: 'ARTICLE 7 - RIGHTS AND OBLIGATIONS OF Magnus',
                    level: 3
                }
            },
            {
                id: 'ZM_cdjavnw',
                type: 'paragraph',
                data: {
                    text: '7.1 Magnus reserves the right to unilaterally change the products, services and contents offered with the Application and close for the third persons including the User any access to and delete the information and contents loaded to the system by the User. Magnus is entitled to exercise this right without any prior notice or without giving time. Magnus shall not be held liable for the losses arising or will arise from the failure of the User in the fulfillment of the revision and/or correction requests made by Magnus within the time granted and shall be entitled to suspend or terminate the User’s right to utilize the Application.&nbsp; &nbsp;'
                }
            },
            {
                id: 'fKDsDDqV-0',
                type: 'paragraph',
                data: {
                    text: '7.2 Magnus does not provide any warranty for uninterrupted accessibility and activity of the services it provides in the Application. Therefore, Magnus disclaims all liability arising from the orders and regulations of the judicial and administrative authorities, force major, third party actions, interruptions and delays in the services provided by the internet providers and similar external factors, wrong use, any technical defects and similar problems, repair works and other occasions arising from Magnus.'
                }
            },
            {
                id: 'aPIbb1ZPqP',
                type: 'paragraph',
                data: {
                    text: '7.3 The Application is designed for the specific and individual use only. If Magnus determines that the User has utilized the Application contrary to its purpose, Magnus may suspend or terminate the membership of the User. In such a case, the User agrees, represents and undertakes that he/she will not be entitled to make any claim against Magnus.'
                }
            },
            {
                id: 'jC1o6ijosA',
                type: 'paragraph',
                data: {
                    text: '7.4 Magnus reserves the right to change or reorganize the services and products available in the Application and its extensions or conditions of use or information provided in the Application or to suspend the operation of the Application without serving a prior notice to the User. Such changes shall be effective with publication. The Users will be deemed to have accepted these changes concerning use of and logging in to the Application.'
                }
            },
            {
                id: 'gCejrvSDyd',
                type: 'paragraph',
                data: {
                    text: '7.5 Magnus may restrict or cease the access to the services if necessary due to a threat affecting the operational security of the network or uninterrupted access to the network or in order to prevent any potential defects occurring in the network, software or registered files and to avoid or minimize the effects of potential failures in the system, and in any required conditions.'
                }
            },
            {
                id: 'UahGhVoQOe',
                type: 'paragraph',
                data: {
                    text: '7.6 Magnus may require the User to provide certain personal information (such as name and surname, electronic mail address and phone number etc.) or complete an information form or survey in order to enhance the quality of its services or invite the User to certain campaigns. Such information collected in the servers and computers of the Company will be used by Magnus in order to carry out seasonal campaigns, design specific promotional activities for the User profiles and to make User classifications in connection with spam mails.'
                }
            },
            {
                id: '3X7JGQXzgy',
                type: 'header',
                data: {
                    text: 'ARTICLE 8 - MEMBERSHIP AND PAYMENTS',
                    level: 3
                }
            },
            {
                id: 'XMTx8Z9Hnu',
                type: 'paragraph',
                data: {
                    text: '8.1 The User agrees and declares that he/she has been made aware of and was given sufficient information about the fact that the Application operates with an internet connection, that any videos, pictures, photos, graphics, texts and visual materials are displayed with an internet connection; that charges payable for internet use will be collected through the User’s wireless network or mobile internet package and Magnus does not provide any undertaking or assume any responsibility for the fees payable against use of the internet.'
                }
            },
            {
                id: 'zu_m6eAaSi',
                type: 'paragraph',
                data: {
                    text: '8.2. The User must pay a charge in order to download the Application and in addition to the monthly Application charge, separate consent will be obtained for the sale price and relevant taxes to be paid against in-App purchases.'
                }
            },
            {
                id: 'rtRK5F8Md6',
                type: 'paragraph',
                data: {
                    text: '8.3 The User agrees, represents and undertakes that Magnus is entitled to unilaterally change the charges, campaigns and packages at any time and will regularly check the updates and Application information for such changes.'
                }
            },
            {
                id: 'kU1jUJ7iD3',
                type: 'paragraph',
                data: {
                    text: '8.4 The User agrees that charges collected will not be refunded under all conditions.'
                }
            },
            {
                id: 'rNC_Pt-wHl',
                type: 'paragraph',
                data: {
                    text: '8.5 User’s device is required to be connected to internet in order to access applications named Apple App Store or Google Play Store. General Terms and Conditions of Apple App Store and Google Play Store will be applied depending on the type of the mobile device and specific use of Apple App Store or Google Play Store.'
                }
            },
            {
                id: 'VdTKzof0-9',
                type: 'paragraph',
                data: {
                    text: '8.6 Magnus may use a third-party payment processor (“Payment Processor”) appropriate for the User’s device in order to collect payments from the User against use of the Application. Payment process is subject to terms and provisions and privacy policy of the Payment Processor in addition to the terms and conditions of this agreement. Magnus shall not be held liable for the losses and/or consequences arising from the Payment Processor. After downloading the Application, the User agrees to pay to Magnus all charges claimed on the basis of current pricing through the Payment Processor and in accordance with the payment provisions and hereby grants consent to Magnus to collect the payment from the selected payment provider of the User (“Payment Method”) to be used through the Payment Processor. The User agrees to make the payment by using the selected Payment Method. Besides that Magnus reserves the right to correct any error or problem even if payment claim was made or payment was sent.&nbsp;'
                }
            },
            {
                id: '17-Bs-qpvk',
                type: 'paragraph',
                data: {
                    text: '8.7 Payment conditions will be determined according to the Payment Method selected by the User or in line with the provisions of the agreement applicable between the User and credit card provider or financial institution.'
                }
            },
            {
                id: 'TEKBQP8m0T',
                type: 'paragraph',
                data: {
                    text: '8.8 After downloading the Application and granting his/her consent to the agreement, the User accepts to pay all charges claimed with the initial utilization of the Application, including all permanent charges to be claimed in the future until the cancellation of the service. The Company will be entitled to collect the monthly membership fee from the User without obtaining any consent for each collection until the User decides to take back his/her consent for the use of the Application.'
                }
            },
            {
                id: 'KYrW8NpNrB',
                type: 'paragraph',
                data: {
                    text: '8.9 The User must provide current, accurate and complete information for use in his/her invoicing account (e.g. inform the Company of all changes occurring in his/her invoice address, credit card number or expiration date of the credit card). If the Payment Method selected by the User is cancelled (e.g. loss or theft) or if a potential security breach such as unauthorized cancellation or unauthorized use of the user name-password is detected, the User must immediately inform Magnus or Payment Processor about this incident. Such information may be changed with the use of security page provided by Apple App Store or Google Play Store depending on the specific purchase.'
                }
            },
            {
                id: 'QxVwzXUUbn',
                type: 'paragraph',
                data: {
                    text: '8.10 In the event of a change in the monthly fee required for the use of the Application (separately from any change in the tax rates or liabilities), Magnus shall inform the User of such change.'
                }
            },
            {
                id: 'DuDWZrERZI',
                type: 'paragraph',
                data: {
                    text: '8.11 As long as the User continues to utilize the Application or does not revoke the payment consent, it shall be deemed that Magnus has granted consent to the invoicing of the User through the selected Payment Method. Magnus may enter these invoiced amounts as debit payable by the User and the User will be liable to pay them. However, that shall not be construed as a waiver made by Magnus of the right to directly collect the payment from the User.'
                }
            },
            {
                id: 'SxUV5DXcNc',
                type: 'paragraph',
                data: {
                    text: '8.12 All free trials and other promotions enabling free access to the Application must be used in the relevant time period. The User must cease using the Application at the end of the free trial period in order to avoid being charged against the use of the Application. If the User is charged by mistake against use of the Application although he/she has cancelled using at the end of the free trial period, the User must contact Magnus through the address info@Magnus.me.'
                }
            },
            {
                id: '57O7PUyAyz',
                type: 'header',
                data: {
                    text: 'ARTICLE 9 - CAMPAIGNS',
                    level: 3
                }
            },
            {
                id: 'YYiH3xrfPC',
                type: 'paragraph',
                data: {
                    text: '9.1 Magnus may organize different campaigns on the internet site and/or Application which be made available to the User from time to time, such as discounts, gifts, offers etc. All terms and conditions of these campaigns shall be determined by Magnus.'
                }
            },
            {
                id: '2PIKdKFyKU',
                type: 'paragraph',
                data: {
                    text: '9.2 Magnus reserves the right to make any kind of change in the contents of the campaigns and offers and to cease the use of offers without any prior notice to the User.'
                }
            },
            {
                id: 'ryfC5ES51A',
                type: 'header',
                data: {
                    text: 'ARTICLE 10 - RIGHT OF WITHDRAWAL AVAILABLE TO THE USER',
                    level: 3
                }
            },
            {
                id: 'xBwcA_1IJD',
                type: 'paragraph',
                data: {
                    text: '10.1 The right of withdrawal as set out in this Section shall be applicable only for the Users acting as a consumer real person or consumer legal entity for purposes other than commercial or professional purpose.'
                }
            },
            {
                id: '0tlzmdslpb',
                type: 'paragraph',
                data: {
                    text: '10.2 Since the Application is download on internet directly by the User, the User will be entitled at all times to terminate his/her membership and withdraw from using the Application.'
                }
            },
            {
                id: 'gQ2m8GaLXf',
                type: 'paragraph',
                data: {
                    text: '10.3 The User’s right of withdrawal of the in-App Purchases, shall be subject to the General Terms and Conditions of Apple App Store or Google Play Store. In such a case, Magnus reserves the right not to refund the payments already collected from the User against the services purchased before by the User.'
                }
            },
            {
                id: 'U7kYs37Fs1',
                type: 'header',
                data: {
                    text: 'ARTICLE 11 - FORCE MAJOR',
                    level: 3
                }
            },
            {
                id: 'pQj0_WiK3T',
                type: 'paragraph',
                data: {
                    text: 'Any strike, adverse weather conditions, epidemics, declared or undeclared war, embargo, revolt, natural disasters and governmental decisions affecting the country in which the device and systems were installed shall be considered as force major to the extent they have a direct impact on the conditions of this agreement. During force major, the responsibilities and duties of the parties shall be suspended and no sanction shall be imposed to the parties due to their inability to perform their respective obligations or no claim shall be made by any of the parties due to the losses arising in this period. If the force major incident persists for more than 1 (one) month, the parties will be entitled to terminate this agreement without assuming any liability towards each other.'
                }
            },
            {
                id: 'ugsl2c2yBj',
                type: 'header',
                data: {
                    text: 'ARTICLE 12 – BINDING EFFECT',
                    level: 3
                }
            },
            {
                id: 'nHISNup5Ie',
                type: 'paragraph',
                data: {
                    text: 'The parties agree and declare that this agreement shall be binding on them and all conditions of this agreement are enforceable between them. The parties undertake to act in strict compliance with the terms and conditions.'
                }
            },
            {
                id: 'xqoBU01PQo',
                type: 'header',
                data: {
                    text: 'ARTICLE 13 – GOVERNING LAW AND AUTHORIZED JURISDICTION',
                    level: 3
                }
            },
            {
                id: 'tnOyFaWWdc',
                type: 'paragraph',
                data: {
                    text: 'This agreement shall be construed and performed according to the laws of Republic of Turkey and all disputes and disagreements arising from this agreement shall be finally resolved by Courts and Enforcement Offices of Istanbul.'
                }
            },
            {
                id: 'eEwbA1V3f7',
                type: 'header',
                data: {
                    text: 'ARTICLE 14 - PRIVACY',
                    level: 3
                }
            },
            {
                id: 'm0LeeQzIBS',
                type: 'paragraph',
                data: {
                    text: '14.1 The Privacy Policy attached to this agreement as an integral part of it shall be binding on the parties and it shall be deemed to be accepted by and made effective for the User once the User downloads the Application to his/her mobile device and completes the membership process.'
                }
            },
            {
                id: 'O-hSM-211q',
                type: 'header',
                data: {
                    text: 'ARTICLE 15 - GENERAL PROVISIONS',
                    level: 3
                }
            },
            {
                id: 'sdMGaBuVHl',
                type: 'paragraph',
                data: {
                    text: '15.1 Any failure or delay by the parties to exercise any of its rights or remedies hereunder shall not be construed as a total waiver of such right or other rights available to the parties under this Agreement.'
                }
            },
            {
                id: '9snlgepC73',
                type: 'paragraph',
                data: {
                    text: '15.2 This Agreement shall be considered as a document of evidence in the settlement of all disputes between the parties pursuant to the provisions of the Civil Procedure Law.'
                }
            },
            {
                id: 'BX0nYo2usc',
                type: 'header',
                data: {
                    text: 'ANNEX-1',
                    level: 2
                }
            },
            {
                id: '5jwWidYm3d',
                type: 'header',
                data: {
                    text: 'PRIVACY POLICY',
                    level: 3
                }
            },
            {
                id: 'uIQHPv3EZn',
                type: 'paragraph',
                data: {
                    text: '1. Purpose of this Privacy Policy is to determine the terms and conditions applicable in the use of the information and data that were shared with Magnus by the real person or legal entity (“User”) downloading the mobile application named Magnus (“Application”) or using the internet site www.Magnus.me (“Web Site”).'
                }
            },
            {
                id: 'fA8o0L-yhQ',
                type: 'paragraph',
                data: {
                    text: '2. Privacy Policy shall be an attachment and integral part of this User Agreement concluded with the User and shall come into force once the User downloads the Application to his/her mobile device and/or starts to use the Internet Site. Magnus is entitled to change these conditions at any time by posting the change on the Application or Internet Site.'
                }
            },
            {
                id: 'VbgAZ72VVY',
                type: 'paragraph',
                data: {
                    text: '3. Once the User decides to download the Application and/or to use the Internet Site, the User agrees to share with Magnus all data provided in the membership process (“Personal Data”) and other information including type/operating system/version of his/her mobile device, site links use while exploring the Internet Site, version/type of the browser, behaviors chosen while exploring or using the Application and/or Internet Site, IP details, errors detected in the Application, Application utilization times, geographical location (data collected automatically by the Application).'
                }
            },
            {
                id: 'Va6YWFzBIA',
                type: 'paragraph',
                data: {
                    text: '4. The User agrees, represents and undertakes that Magnus will be entitled to use such data without any need for further consent for the purposes and in the manner described below:'
                }
            },
            {
                id: 'hlRGCGaL0E',
                type: 'list',
                data: {
                    style: 'unordered',
                    items: [
                        'Analysis, scientific study or statistical processes,',
                        'Magnus will analyze and interpret the visitor preferences and attitudes observed about the User. These statistical data that do not contain any personal information may be shared with the business partners of the Company for various promotions, advertisements and campaigns and for the purpose of reorganization/integration of additional function to the Application in order to achieve optimum customer satisfaction. The User agrees that his/her personal information and data may be shared with the third parties acting as business partner of the Company for the above mentioned purposes.&nbsp;',
                        'Besides that, the User agrees that Magnus may use Personal Data or data collected automatically by the Application in order to send bulletins and communications, resolve technical mistakes, provide User specific contents and offers, design and provide campaigns/promotions/advertisements, make sales for the Application and develop the design and functions of the Application.'
                    ]
                }
            },
            {
                id: 'eBiLq5x_tb',
                type: 'paragraph',
                data: {
                    text: '5. The User will be entitled to exercise the rights detailed below in connection with the protection of his/her Personal Data:'
                }
            },
            {
                id: '6OrGn2F_ag',
                type: 'list',
                data: {
                    style: 'unordered',
                    items: ['Learning whether their Personal Data has been processed,']
                }
            },
            {
                id: 'ENcM8UtnIB',
                type: 'list',
                data: {
                    style: 'unordered',
                    items: [
                        'Demanding information about processing if their Personal Data was processed',
                        'Learning the specific purpose of processing and whether they are used in accordance with relevant purposes,',
                        'Learning the third persons to whom Personal Data was transferred in or outside of Turkey,',
                        'Requiring correction of their Personal Data if they had been processed inaccurately or deficiently,',
                        'Requiring deletion or destruction of their Personal Data,',
                        'Demanding notification of correction, deletion or destruction requests to the third persons to whom Personal Data was transferred and raising an objection against the results detrimental to them due to analysis of your Personal Data exclusively by automated means, or'
                    ]
                }
            },
            {
                id: '28PHS6gZSS',
                type: 'list',
                data: {
                    style: 'unordered',
                    items: [
                        'Claiming remedy for the losses incurred by them due to illegal processing of their Personal Data.'
                    ]
                }
            },
            {
                id: 'kAuue8Fz08',
                type: 'paragraph',
                data: {
                    text: 'The User must communicate his/her demands relating to the protection of his/her Personal data to info@Magnus.me. All demands will be diligently examined and contact will be made with the User as soon as possible.'
                }
            },
            {
                id: 'Vi_brN70ns',
                type: 'paragraph',
                data: {
                    text: 'If the User demands the copy of the data kept in the system of Magnus, data requested will be shared with the User after performing the necessary security check.'
                }
            },
            {
                id: 'l1n5Nmv5-R',
                type: 'paragraph',
                data: {
                    text: '6. Sessions cookies will be retained in the User’s device in order to enable faster and more efficient use of the Application. The User may prevent keeping of such data by making changes in the settings of his/her device. However, in such a case, the User may not use all functions of the Application.'
                }
            },
            {
                id: '-obyFWxDwz',
                type: 'paragraph',
                data: {
                    text: '7. Any User information will be retained in the Company’s database for a period of 3 (three) years after deletion of the Application or termination of the membership by the User.'
                }
            },
            {
                id: '4muVvtbRW9',
                type: 'paragraph',
                data: {
                    text: '8. The User agrees that Magnus might obtain information about him/her through the relationships established with the Third Parties (e.g. business partners, subagreementors providing technical, payment and distribution services, advertisement networks, analysis providers, search data retainers, credit reference agencies) (“Information Obtained from Other Sources”).'
                }
            },
            {
                id: 'PgSkfeBDWn',
                type: 'paragraph',
                data: {
                    text: '9. The User hereby grants his/her consent to Magnus to share his/her information with the third parties for the purposes set forth in this Agreement. The User further grants his/his consent to the utilization of his/her personal information in the following circumstances:.'
                }
            },
            {
                id: 'zOzjeqwQBo',
                type: 'list',
                data: {
                    style: 'unordered',
                    items: [
                        'When Magnus needs to use a third service provider to provide or offer the services,',
                        'Legal or security related requirements and requirements under the applicable legislation,',
                        'Transfer/sale of the Company,',
                        'Protection of the rights and ownership of Magnus and protection of the rights and ownership of other users and third persons.'
                    ]
                }
            },
            {
                id: 'AGGBB-lb99',
                type: 'paragraph',
                data: {
                    text: '10. The user expressly consents to the processing and transfer of their personal data in the manner shown in the Privacy Policy and the Information Text.'
                }
            },
            {
                id: 'sbsnYmjBfN',
                type: 'paragraph',
                data: {
                    text: '11. It is possible that links to other sites or applications are given in the Application or on the Internet Site and Magnus does not assume any responsibility for the privacy practices and contents of such sites and applications.'
                }
            },
            {
                id: '3-yZUDuAqw',
                type: 'paragraph',
                data: {
                    text: '12. During the term of this Agreement and after its expiration, Magnus and/or its affiliates and/or 3rd&nbsp;persons making business with Magnus may send electronic mails, short messages or other notices to the User for marketing and advertising purposes under this consent granted by the User. However, the User is entitled to withdraw such consent at any time and may communicate this demand by sending an e-mail to the address info@getmagnus.app.'
                }
            },
            {
                id: '2Bfvyl1na9',
                type: 'paragraph',
                data: {
                    text: '13. Magnus reserves the right to make changes to this Privacy Policy at any time. User will be informed of such changes without delay.'
                }
            },
            {
                id: '0GGltCTg4I',
                type: 'paragraph',
                data: {
                    text: '14. This Privacy Policy consists of 14 (fourteen) clauses and is an integral part of the Mobile Application User Agreement.'
                }
            }
        ],
        version: '2.24.3'
    },
    tr: {
        time: 1663769196423,
        blocks: [
            {
                id: 'L-Oh9ayi9Q',
                type: 'header',
                data: {
                    text: 'MOBİL UYGULAMA KULLANICI SÖZLEŞMESİ',
                    level: 2
                }
            },
            {
                id: 'q-oR_Kqjyt',
                type: 'header',
                data: {
                    text: 'MADDE 1 – TARAFLAR',
                    level: 3
                }
            },
            {
                id: '0OYeBuDNud',
                type: 'paragraph',
                data: {
                    text: 'İşbu sözleşme ve ekleri (EK-1 GizlilikPolitikası) “Magnus” (bundan böyle "Magnus" olarak anılacaktır) ile mobil uygulamadan iş bu sözleşmede belirtilen koşullar dahilinde yararlanan Kullanıcı ile karşılıklı olarak kabul edilerek yürürlüğe girmiştir.'
                }
            },
            {
                id: 'LwKCMhlN6s',
                type: 'header',
                data: {
                    text: 'MADDE 2 – TANIMLAR',
                    level: 3
                }
            },
            {
                id: 'u-SQnIz_Bu',
                type: 'paragraph',
                data: {
                    text: '2.1. Uygulama: Magnus isimli mobil uygulama tüm mobil cihazlarda çalışan mobil uygulama yazılımı ve bu yazılım ile bağlantılı olarak oluşturulan ürün ve hizmetlerin sunulduğu tüm sanal gerçeklik mecralarını ifade etmektedir. (Bundan böyle "Uygulama" olarak anılacaktır.)'
                }
            },
            {
                id: 'yrzNUdvkRm',
                type: 'paragraph',
                data: {
                    text: '2.2. Kullanıcı: Uygulamadan Magnus tarafından sunulan hizmetlerden işbu sözleşmede belirtilen koşullar dahilinde yararlanan kişileri ifade etmektedir. (Bundan böyle "Kullanıcı” olarak anılacaktır.)'
                }
            },
            {
                id: '1iiKLhH-ea',
                type: 'paragraph',
                data: {
                    text: '2.3. Sisteme Erişim Araçları: Kullanıcının hesabını yönetim sayfası, Uygulamaya erişim sağlayan kullanıcı adı, şifre, kod, parola gibi yalnızca Kullanıcının bilgisinde olan bilgileri ifade eder.'
                }
            },
            {
                id: 'V0aDtxg5qv',
                type: 'paragraph',
                data: {
                    text: '2.4. İletişim Kanalları: Anında bildirim, e-posta, sms, mms, telefon ile bildirim gibi iletişim mecralarını ifade eder.'
                }
            },
            {
                id: 'cU9cWisRLL',
                type: 'paragraph',
                data: {
                    text: '2.5. Mesaj: Magnus’ın Kullanıcılara pazarlama, araştırma, bilgilendirme vb. amaçlarla göndereceği, Kullanıcıların Magnus tarafından kendilerine gönderilmesine izin verdikleri mesajları ifade eder.'
                }
            },
            {
                id: '9vAQzbxzt2',
                type: 'paragraph',
                data: {
                    text: '2.6. Kullanıcı Hesabı Yönetim Sayfası: Kullanıcının yalnızca sisteme erişim araçlarını kullanarak ulaştığı üyeliğine ilişkin iş ve işlemlerde bulunduğu kendisine özel web sayfasını ifade etmektedir. (Bundan böyle kısaca "Portal" olarak anılacaktır.)'
                }
            },
            {
                id: 'PC5kNaB8xp',
                type: 'paragraph',
                data: {
                    text: '2.7 Ödeme İşlemcisi: Magnus’ın Uygulama ücretini tahsil için kullancağı, Kullanıcı’nın cihazına uygun olan üçüncü şahıs ödeme alt yapısını ifade etmektedir. Bunlar Apple Pay yada Google Pay olarak adlandırılır.'
                }
            },
            {
                id: 'wZNiiM9b05',
                type: 'header',
                data: {
                    text: 'MADDE 3 – KONU VE KAPSAM',
                    level: 3
                }
            },
            {
                id: '3UEOmfUoFZ',
                type: 'paragraph',
                data: {
                    text: '3.1. İşbu sözleşme Uygulama’nın kullanım koşulları ve Kullanıcı ile Magnus’ın hak ve yükümlülüklerini düzenler.'
                }
            },
            {
                id: 'LjWeJSGvGJ',
                type: 'paragraph',
                data: {
                    text: '3.2. Kullanıcı, işbu sözleşme hükümlerini kabul etmekle, Uygulama içinde yer alan kullanıma, üyeliğe ve hizmetlere ilişkin Magnus tarafından açıklanan her türlü beyanı da kabul etmiş olmaktadır. Kullanıcı, bahsi geçen beyanlarda belirtilen her türlü hususa uygun olarak davranacağını kabul, beyan ve taahhüt eder.'
                }
            },
            {
                id: 'Fp5TtHJwCW',
                type: 'header',
                data: {
                    text: 'MADDE 4 - BAŞVURU VE ÜYELİK',
                    level: 3
                }
            },
            {
                id: 'YtG5NJHqvq',
                type: 'paragraph',
                data: {
                    text: '4.1 Kullanıcı, Uygulama’yı indirip, kayıt için gerekli olan bölümleri doldurup "Kullanıcı Sözleşmesi\'ni okudum, müzakere ettim ve sözleşme şartlarını kabul ediyorum" maddesini onayladıktan sonra iş bu Sözleşme kapsamında Kullanıcı olma ehliyetine sahip olduğunu beyan ve taahhüt etmektedir. Aksi halde, bu hususun Magnus tarafından tespit edilmesi durumunda Kullanıcının sözleşmesi derhal feshedilir.'
                }
            },
            {
                id: 'K2QjnL9-76',
                type: 'paragraph',
                data: {
                    text: '4.2 Kullanıcı, başvuru esnasında verdiği tüm bilgilerin daima ve her bakımdan eksiksiz, gerçeğe uygun ve güncel olduğunu kabul eder. Kullanıcı, bilgilerin eksik veya gerçeğe aykırı olarak bildirilmesinden ve bu eksik veya gerçeğe aykırı bildirimden kaynaklanabilecek sonuçlardan sorumludur. Kullanıcı, her zaman kişisel bilgilerini Uygulama aracılığıyla güncelleyebilir.'
                }
            },
            {
                id: 'yp7US53tK3',
                type: 'paragraph',
                data: {
                    text: '4.3 Aylık uygulama bedeli Kullanıcı’nın cihazına uygun uygulama merkezi’ne kayıtlı kredi kartından aylık olarak tahsil edilecektir.'
                }
            },
            {
                id: 'ptnFIGTow6',
                type: 'header',
                data: {
                    text: 'MADDE 5 - HİZMETİN TANIMI',
                    level: 3
                }
            },
            {
                id: 'IH82sbfLTt',
                type: 'paragraph',
                data: {
                    text: '5.1 “Magnus” Uygulaması, Kullanıcı’larını deprem konusunda bilinçlendirmeye yardımcı olan, sismik hareketlerden haberdar eden, zemin bilgisi ve alarm servisi ile depreme hazırlıklı olmalarını sağlamaya yardımcı olacak görsel, işitsel ya da yazılı olarak bilgilendirme niteliğinde veriler sunan bir uygulamadır. Uygulama, depremle ilgili hiçbir şekilde öngörü veya erken uyarı özelliği sunmamaktadır. Kullanıcıların uygulama ekranlarından gördükleri deprem sayıları ve lokasyon bilgileri doğrultusunda aldıkları kararlar ve aksiyonlar Magnus’ın sorumluluğunda değildir.'
                }
            },
            {
                id: 'jdVtYoGZyJ',
                type: 'header',
                data: {
                    text: 'MADDE 6 - KULLANICININ HAK VE YÜKÜMLÜLÜKLERİ',
                    level: 3
                }
            },
            {
                id: 'A6n6zsX2-O',
                type: 'paragraph',
                data: {
                    text: '6.1 Kullanıcı, kullanıcı prosedürlerini yerine getirirken, Uygulamadaki ürün veya hizmetlerden faydalanırken ve Uygulamadaki ürün veya hizmetler ile ilgili herhangi bir işlemi yerine getirirken işbu sözleşme içerisinde yer alan tüm şartlara ve yürürlükteki tüm mevzuata uygun hareket edeceğini, işbu sözleşmede belirtilen tüm şart ve kuralları okuduğunu, anladığını ve onayladığını kabul eder.'
                }
            },
            {
                id: 'HZjQJkm5v_',
                type: 'paragraph',
                data: {
                    text: '6.2 Kullanıcı, Uygulamadaki içerik ve verileri Uygulamayı işbu sözleşmenin 4. Maddesinde belirtilen şekilde indirip üyeliğini tamamlayarak abone olmasını müteakiben, kullanma hakkına sahip olacaktır.'
                }
            },
            {
                id: '0lWzx414vi',
                type: 'paragraph',
                data: {
                    text: '6.3 Kullanıcı, Uygulama’yı indirerek 18 yaşının üzerinde olduğunu beyan ve kabul eder. 18 yaşını doldurmamış olan Kullanıcı’nın veli veya vasisinin bu sözleşmeye açık onayını almış olduğu kabul edilir.'
                }
            },
            {
                id: 'YyLSieFR4_',
                type: 'paragraph',
                data: {
                    text: '6.4 Kullanıcı işbu uygulamanın Magnus’a ait olduğunu ve onun tarafından işletildiğini kabul eder. İşbu uygulamanın içeriği Fikri ve Sınai haklar doğrultusunda korunmaktadır. Kullanıcı, Magnus ve/veya başka bir üçüncü şahsın aynı veya şahsi haklarına, malvarlığına tecavüz teşkil edecek nitelikteki Uygulama dahilinde bulunan resimleri, metinleri, görsel ve işitsel imgeleri, video klipleri, dosyaları, veri tabanlarını, katalogları ve listeleri çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini kabul ve taahhüt etmektedir.'
                }
            },
            {
                id: 'qQaBSCN5_-',
                type: 'paragraph',
                data: {
                    text: '6.5 Kullanıcı, Uygulamayı kullanırken Türk Ceza Kanunu, Türk Ticaret Kanunu, Borçlar Kanunu, 5651 sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun, Fikir ve Sanat Eserleri Kanunu, Kişisel Verilerin Korunması Kanunu, Sınai Mülkiyet Kanunu, halihazırda yürürlükte olan ilgili her türlü mevzuat hükümleri ile Magnus’ın Uygulamaya ilişkin olarak yayımlayacağı bildirimlere uymayı kabul eder.'
                }
            },
            {
                id: '3xR9og8wdF',
                type: 'paragraph',
                data: {
                    text: '6.6 Uygulamada ve Uygulama üzerinden link verilen internet sitelerinde, Kullanıcı da dahil olmak üzere üçüncü kişiler tarafından yayınlanan içeriklerden dolayı Magnus’ın sorumluluğu bulunmamaktadır. Herhangi bir üçüncü kişi tarafından sağlanan ve yayınlanan bilgilerin, içeriklerin, görsel ve işitsel imgelerin doğruluğu ve hukuka uygunluğunun taahhüdü bütünüyle bu eylemleri gerçekleştiren kişilerin sorumluluğundadır. Magnus üçüncü kişiler tarafından sağlanan hizmetlerin ve içeriklerin güvenliğini, doğruluğunu ve hukuka uygunluğunu taahhüt ve garanti etmemektedir.'
                }
            },
            {
                id: 'reuwJYWPvt',
                type: 'paragraph',
                data: {
                    text: "6.7 Kullanıcı ve Magnus birbirinden bağımsız taraflardır. İşbu Sözleşme'nin Taraflarca onaylanması ve uygulanması, aralarında ortaklık, temsilcilik veya işçi– işveren ilişkisi olduğu sonucunu doğurmaz."
                }
            },
            {
                id: 'yLRRcIcdl2',
                type: 'header',
                data: {
                    text: 'MADDE 7 - Magnus’IN HAK VE YÜKÜMLÜLÜKLERİ',
                    level: 3
                }
            },
            {
                id: '4N3UYatTUc',
                type: 'paragraph',
                data: {
                    text: "7.1 Magnus, Uygulama'da sunulan ürün ve hizmetleri ve içerikleri her zaman tek taraflı olarak değiştirebilme, Kullanıcının sisteme yüklediği bilgileri ve içerikleri Kullanıcı da dahil olmak üzere üçüncü kişilerin erişimine kapatabilme ve silme hakkını saklı tutmaktadır. Magnus, bu hakkını hiçbir bildirimde bulunmadan ve önel vermeden kullanabilir. Magnus tarafından Kullanıcıdan talep edilen değişiklik ve/veya düzeltme istekleri belirtilen süre içerisinde yerine getirilmediği takdirde doğmuş ve doğabilecek zararlardan Magnus sorumlu olmayacak ve Kullanıcının Uygulamayı kullanma hakkını dondurabilme veya sonlandırma hakkına sahip olacaktır."
                }
            },
            {
                id: '5L8ai27_PK',
                type: 'paragraph',
                data: {
                    text: '7.2 Magnus, Uygulama kapsamında verdiği hizmetlerin sürekli aktif ve erişilebilir olacağına dair hiçbir garanti vermemektedir. Magnus özellikle yargı ve sair yetkili resmi merci karar ve uygulamaları, mücbir sebepler, üçüncü kişilerin sebep olduğu durumlar, Internet bağlantı hizmeti sağlayan kuruluşlardan kaynaklanan aksaklıklar ve gecikmeler ve benzeri dış etkenler, yanlış kullanım, Magnus’dan kaynaklanabilecek teknik arıza ve sair aksaklıklar, tamir çalışmaları veya diğer yönlendirmeler sonucu meydana gelen aksaklıklar konusunda hiçbir şekilde sorumluluk kabul etmez.'
                }
            },
            {
                id: 'E0qmSxT9k2',
                type: 'paragraph',
                data: {
                    text: '7.3 Uygulama kişiye özel ve bireysel kullanım içindir. Magnus, Kullanıcı’nın Uygulama’yı kullanım amacına aykırı olarak kullandığını tespit etmesi durumunda Kullanıcı’nın üyeliğini durdurabilir, askıya alabilir ya da tamamen sonlandırabilir. Bu halde Kullanıcı Şirket’ten herhangi bir isim altında hiçbir talepte bulunamayacağını kabul, beyan ve taahhüt etmiştir.'
                }
            },
            {
                id: 'lsToQpXFTl',
                type: 'paragraph',
                data: {
                    text: '7.4 Magnus, işbu Uygulama ve uzantısında mevcut her tür hizmet, ürün, Uygulamayı kullanma koşulları ile Uygulamada sunulan bilgileri önceden bir ihtara gerek olmaksızın değiştirme, yeniden organize etme, Uygulamayı durdurma hakkını saklı tutar. Değişiklikler, Uygulamada yayım anında yürürlüğe girer. Kullanıcılar, Uygulamanın kullanımı ya da Uygulamaya giriş ile bu değişiklikleri de kabul etmiş sayılır.'
                }
            },
            {
                id: 'LnkWQoe90H',
                type: 'paragraph',
                data: {
                    text: '7.5 Magnus gerektiğinde, ağın işletim güvenliğinin tehlikede olması, ağa erişimin devamlılığı, ağda, yazılımda veya kayıtlı dosyalarda meydana gelebilecek arızaların önüne geçebilmek, muhtemel aksaklıkları engellemek veya etkisini azaltmak adına ve gerekli gördüğü diğer durumlarda hizmete erişimi sınırlandırabilir veya durdurabilir.'
                }
            },
            {
                id: '3hikv25vHn',
                type: 'paragraph',
                data: {
                    text: '7.6 Magnus, Kullanıcılarına daha iyi bir hizmet verebilmek ya da kendilerini çeşitli kampanyalara davet edebilmek amacıyla amacıyla Kullanıcı’nın bazı kişisel bilgilerini (ad ve soyad, elektronik posta adresi, telefon numarası vb.) talep edebilir ve bu amaçla Kullanıcı’ya anket ya da bilgi formu doldurtabilir. Şirket sunucu ve bilgisayarlarında toplanan bu bilgiler, dönemsel kampanya çalışmaları, kullanıcı profillerine yönelik özel promosyon faaliyetlerinin kurgulanması ve istenmeyen iletilere yönelik Kullanıcı sınıflandırma çalışmalarında Magnus tarafından kullanılabilecektir.'
                }
            },
            {
                id: 'V0KXOiupJA',
                type: 'header',
                data: {
                    text: 'MADDE 8 -ÜYELİK VE ÖDEMELER',
                    level: 3
                }
            },
            {
                id: 'qsZqOuvZH4',
                type: 'paragraph',
                data: {
                    text: '8.1 Kullanıcı, Uygulama’nın internet bağlantısı ile çalıştığını, Uygulama içeriğindeki video, resim, fotoğraf, grafik, yazı ve görsellerin internet bağlantısı ile görüntülendiğini, internet kullanım bedellerinin Kullanıcı’nın kablosuz ağı veya mobil internet paketi üzerinden karşılanacağını, İnternet kullanım bedellerine ait Magnus’ın herhangi bir taahhüdü veya sorumluluğu olmadığını bildiğini ve bu hususlarda kendisinin bilgilendirildiğini kabul ve beyan eder.'
                }
            },
            {
                id: 'x5IVQyvfEN',
                type: 'paragraph',
                data: {
                    text: '8.2. Uygulamayı yüklemek ücretli olup, aylık uygulama ücreti haricinde uygulama içi satın almalarda tüm vergiler dâhil satış fiyatı için ayrıca onay alınacaktır.'
                }
            },
            {
                id: 'vPDzC-z78S',
                type: 'paragraph',
                data: {
                    text: '8.3 Kullanıcı, Magnus’ın ücretlerini, kampanya ve paketleri her zaman tek taraflı olarak değiştirebileceğini, yapılan değişiklikler için güncelleme ve uygulama bilgilerini düzenli olarak kontrol edeceğini kabul, beyan ve taahhüt eder.'
                }
            },
            {
                id: 'ubxGnT1ghM',
                type: 'paragraph',
                data: {
                    text: '8.4 Kullanıcı, hiçbir şekilde ücret iadesi yapılmadığını ve yapılmayacağını kabul eder.'
                }
            },
            {
                id: '8uVj8WLQ0Q',
                type: 'paragraph',
                data: {
                    text: '8.5 Kullanıcı’nın mobil cihazının, Apple App Store veya Google Play Store uygulamalarına erişimi için Kullanıcı’nın internete bağlı olması talep edilebilir. Apple App Store ve Google Play Store’un Genel Hüküm ve Koşulları mobil cihazın çeşidine ve Apple App Store veya Google Play Store kullanımına bağlı olarak uygulanacaktır.'
                }
            },
            {
                id: 'bm-JDShFo5',
                type: 'paragraph',
                data: {
                    text: '8.6 Magnus Uygulama’nın kullanımı için Kullanıcılardan ödeme almak için Kullanıcı’nın cihazına uygun bir üçüncü-şahıs ödeme işlemcisi (“Ödeme İşlemcisi”) kullanabilir. Ödeme süreci, Sözleşme’ye ek olarak Ödeme İşlemcisi’nin hüküm, koşul ve gizlilik şartlarına tabi olacaktır. Magnus, Ödeme İşlemcisi sebebiyle meydana gelecek herhangi bir zarar ve/veya sonuçtan sorumlu değildir. Uygulama’yı indirmekle Kullanıcı, Magnus’a Ödeme İşlemcisi yoluyla geçerli fiyatlandırmadaki tüm ücretleri, ödeme hükümleri uyarınca ödemeyi kabul eder ve Ödeme İşlemcisi yoluyla Kullanıcı’nın seçilmiş ödeme sağlayıcısından (“Ödeme Metodu”) ödeme alınması için Magnus’a izin verir. Kullanıcı, seçilmiş Ödeme Metodu’nu kullanarak ödeme yapmayı kabul eder. Talep gönderilmiş veya ödeme yapılmış olsa dahi Magnus herhangi bir hata veya sorunu düzeltme hakkını saklı tutar.'
                }
            },
            {
                id: 'Eq3J1h9eMM',
                type: 'paragraph',
                data: {
                    text: '8.7 Ödemelere ilişkin şartlar, Kullanıcıların seçmiş olduğu Ödeme yöntemi veya kredi kartı sağlayıcıları veya finansal kuruluşlarıyla aralarındaki sözleşme hükümlerine bağlı olacaktır.'
                }
            },
            {
                id: 'qWqFJ1QZSc',
                type: 'paragraph',
                data: {
                    text: '8.8 Kullanıcı, Uygulamayı indirerek ve işbu sözleşmeyi onaylayarak, ilk kullanımda yapılan ücretlendirmeyi ve sonrasında yapılacak sürekli ücretlendirmeleri ve servisin iptal edilmesine kadar devam edecek tüm sürekli ücretlendirmeleri kabul eder. Kullanıcı verdiği onayı sonlandırana kadar Şirket, ileride onay almaksızın Kullanıcı’dan aylık üyelik bedelini tahsil edecektir.'
                }
            },
            {
                id: 'pfh99spV2T',
                type: 'paragraph',
                data: {
                    text: '8.9 Kullanıcıların fatura hesabı için güncel, tam ve doğru bilgileri sağlaması gerekmektedir (fatura adresinde, kredi kartı numarasında veya kredi kartı son kullanma tarihinde değişiklik olması durumları gibi). Kullanıcı’nın ödeme yöntemleri iptal edildiyse (örn: kayıp veya çalınma), onaysız kapanma veya kullanıcı adı – şifre kullanımı gibi bir güvenlik ihlali ihtimalini fark etmişlerse, derhal Magnus’ı veya ödeme işlemcisini bu konuda bilgilendirmelidirler. Bu tip bilgilerin değişikliği Apple App Store veya Google Play Store’ un satın alıma bağlı olan güvenlik sayfasından yapılabilir.'
                }
            },
            {
                id: 'Prm7JDm72G',
                type: 'paragraph',
                data: {
                    text: '8.10 Eğer Uygulamanın aylık ücreti değişime uğrarsa (vergi miktarlarındaki değişiklik veya yükümlülükten farklı olarak) Magnus, bu değişikliği Kullanıcı’ya bildirecektir.'
                }
            },
            {
                id: '1clLmlVFc9',
                type: 'paragraph',
                data: {
                    text: '8.11&nbsp;&nbsp;Kullanıcı’nın Uygulama’yı kullanmaya devam etmesi veya iptal etmemesi, Magnus’ın Kullanıcı’nın Ödeme yönteminden faturalandırmasına onay vermiş olduğu anlamına gelir. Magnus düzenlediği bu fatura miktarlarını Kullanıcı tarafından ödenmek üzere borç kaydedebilir ve Kullanıcı bu miktarları ödemekten sorumludur. Bu, Magnus’ın Kullanıcı’dan direkt olarak ödeme alma hakkından feragat ettiğini göstermez.'
                }
            },
            {
                id: 'mz-VAlBTGO',
                type: 'paragraph',
                data: {
                    text: '8.12 Uygulama’ya erişim sağlayan tüm ücretsiz deneme sürümleri veya promosyonlar deneme sürümü için belirlenen zaman dilimi içerisinde kullanılmalıdır. Kullanıcı, Uygulama için deneme sürümü sürecinin sonunda herhangi bir ücretlendirme gerçekleşmemesi için deneme sürümü süresinin bitmesinden önce Uygulama kullanımını durdurmalıdır. Eğer Kullanıcı, deneme sürümü süresinin bitmesinden önce kullanımını iptal ederse ve buna rağmen sehven ücretlendirilirse Magnus ile info@getmagnus.app adresinden iletişime geçmelidir.'
                }
            },
            {
                id: 'dtcc1wjw4p',
                type: 'header',
                data: {
                    text: 'MADDE 9 - KAMPANYALAR',
                    level: 3
                }
            },
            {
                id: 'WOagKbPDBS',
                type: 'paragraph',
                data: {
                    text: '9.1 Magnus, Kullanıcı’nın yararlanması için İnternet Sitesi ve/veya Uygulama üzerinden zaman zaman çeşitli indirim, hediye, fırsat, teklif vb. kampanyalar yapabilir. İşbu kampanyaların tüm şart ve koşulları Magnus tarafından düzenlenecektir.'
                }
            },
            {
                id: 'Nt0mL_iZOL',
                type: 'paragraph',
                data: {
                    text: '9.2 Magnus kampanya ve tekliflerin içeriği ile ilgili her türlü değişiklik yapma ve kampanya ve özel tekliflerin yürütme hakkını önceden ve habersiz durdurma hakkını saklı tutar.'
                }
            },
            {
                id: '1XyVFFQWvn',
                type: 'header',
                data: {
                    text: 'MADDE 10 - KULLANICININ CAYMA HAKKI',
                    level: 3
                }
            },
            {
                id: 'REM9bhHNUh',
                type: 'paragraph',
                data: {
                    text: '10.1 Bu maddede düzenlenen cayma hakkı, yalnızca ticari veya mesleki olmayan amaçlarla hareket eden tüketici sıfatındaki gerçek veya tüzel kişi Kullanıcılar için geçerlidir.'
                }
            },
            {
                id: 'fdLSJGFKWi',
                type: 'paragraph',
                data: {
                    text: '10.2 Uygulama, doğrudan Kullanıcı tarafından internet üzerinden indirildiğinden Kullanıcı’nın dilediği zaman üyeliğini sonlandırarak cayma hakkı bulunmaktadır.'
                }
            },
            {
                id: 'Q8FgJt5AD_',
                type: 'paragraph',
                data: {
                    text: '10.3 Uygulama içi satın almalar için Kullanıcının cayma hakkı Apple App Store veya Google Play Store’un Genel Hüküm ve Koşullarına tabidir. Bu durumda Magnus, Kullanıcının satın almış olduğu hizmetlere ilişkin alacak hakkını talep ile tahsil edilmiş ödemeleri iade etmeme haklarını saklı tutar.'
                }
            },
            {
                id: 'tjWXl2a6re',
                type: 'header',
                data: {
                    text: 'MADDE 11 - MÜCBİR SEBEPLER',
                    level: 3
                }
            },
            {
                id: 'b41yiQAijy',
                type: 'paragraph',
                data: {
                    text: 'Cihaz ve sistemlerin kurulu bulunduğu ülkede grev, kötü hava koşulları, salgın hastalıklar, ilan edilmiş olsun ya da olmasın savaş, ambargo, ayaklanma, doğal afetler ve hükümet kararları sözleşme koşullarını doğrudan etkilediği ölçüde mücbir sebeptir. Mücbir sebep süresinde, Taraflar’ın sorumluluk ve vazifeleri askıya alınacak ve bu süre içinde sözleşme hükümlerinin yerine getirilememesi nedeniyle herhangi bir yaptırım uygulanmayacak veya Taraflar birbirlerinden zararların tazminini talep etmeyecektir. Bu mücbir sebep durumu 1 (bir) aydan fazla sürer ise, Taraflar başka herhangi bir sorumluluk altına girmeksizin bu sözleşmeyi feshetme hakkına sahip olacaktır.'
                }
            },
            {
                id: 'mVftOkT3Wk',
                type: 'header',
                data: {
                    text: 'MADDE 12 - BAĞLAYICILIK',
                    level: 3
                }
            },
            {
                id: 'Pl-bYCe4BO',
                type: 'paragraph',
                data: {
                    text: 'Taraflar işbu sözleşmenin Taraflar üzerinde bağlayıcı olduğunu ve işbu sözleşmede yer alan şartların kendi aralarında tam olarak uygulanabilir olduğunu kabul ve beyan eder ve bunlara uygun hareket etmeyi kati surette taahhüt ederler.'
                }
            },
            {
                id: '1ruZhqkO46',
                type: 'header',
                data: {
                    text: 'MADDE 13 - HUKUK VE YETKİLİ MAHKEME',
                    level: 3
                }
            },
            {
                id: '_udNiAPD0Y',
                type: 'paragraph',
                data: {
                    text: 'Bu sözleşmenin yorumunda ve işbu sözleşme sebebiyle ortaya çıkacak olan tüm uyuşmazlıklarda İstanbul Mahkemeleri ve İcra Daireleri yetkili olup, Türk hukuku uygulanır.'
                }
            },
            {
                id: '9VQ44l4-fK',
                type: 'header',
                data: {
                    text: 'MADDE 14 - GİZLİLİK',
                    level: 3
                }
            },
            {
                id: 'fk211sK9IP',
                type: 'paragraph',
                data: {
                    text: '14.1 İşbu sözleşmenin ayrılmaz bir parçasını oluşturan gizlilik politikası esas alınacak ve Kullanıcı’nın Uygulama’yı mobil cihazına indirmesini takiben üye kaydı oluşturması ile birlikte, Kullanıcı tarafından kabul görmüş ve yürürlüğe girmiş sayılacaktır.'
                }
            },
            {
                id: 'Tb9ekPFiby',
                type: 'header',
                data: {
                    text: 'MADDE 15 - GENEL HÜKÜMLER',
                    level: 3
                }
            },
            {
                id: '9q2HnXgg-W',
                type: 'paragraph',
                data: {
                    text: '15.1 Taraflar’dan herhangi birinin sözleşmede belirtilen haklardan veya yetkilerden yahut müeyyidelerden birini uygulamaması, söz konusu hakkın ve takip eden hakların kaybı anlamına gelmez ve bu yönde bir teamül oluşturamaz.'
                }
            },
            {
                id: 'Jz6krr4zFu',
                type: 'paragraph',
                data: {
                    text: '15.2 İş bu sözleşme Hukuk Muhakemeleri Kanunu uyarınca uyuşmazlıkların çözümünde ispata yönelik bir belge niteliğindedir.'
                }
            },
            {
                id: 'QINy95RDGE',
                type: 'header',
                data: {
                    text: 'EK-1',
                    level: 2
                }
            },
            {
                id: 'ohdAj3V0M6',
                type: 'header',
                data: {
                    text: 'GİZLİLİK POLİTİKASI',
                    level: 3
                }
            },
            {
                id: 'zLgPdyIVqm',
                type: 'paragraph',
                data: {
                    text: '1. İşbu Gizlilik Politikası’nın amacı, Magnus mobil uygulamasını (“Uygulama”) indiren veya www.Magnus.me internet sitesini (“İnternet Sitesi”) kullanan gerçek veya tüzel kişi (“Kullanıcı”) tarafından Magnus ile paylaşılan bilgi ve verilerin kullanımına ilişkin koşul ve şartları tespit etmektir.'
                }
            },
            {
                id: 'cuGZElkjPh',
                type: 'paragraph',
                data: {
                    text: '2. Gizlilik Politikası, Kullanıcı ile akdedilen işbu Kullanıcı Sözleşmesi’nin eki ve ayrılmaz bir parçası niteliğindedir ve kullanıcının Uygulamayı mobil cihazına indirmesi ve/veya İnternet sitesini kullanması ile yürürlüğe girer. Magnus bu koşulları Uygulama ya İnternet Sitesi üzerinde yayınlamak koşulu ile dilediği zaman değiştirme hakkına sahiptir.'
                }
            },
            {
                id: 'tqfyx9KtZC',
                type: 'paragraph',
                data: {
                    text: '3. Kullanıcı, Uygulamayı indirdiğinde ve/veya İnternet Sitesini kullandığında, üyelik esnasında bildirdiği veriler(“Kişisel Veriler”)&nbsp;&nbsp;ile kullandıkları cihazın türü/işletim sistemi/versiyonu, İnternet sitesinde gezdiği site linkleri, tarayıcı versiyonu/türü, uygulama ve/veya İnternet sitesi içindeki gezinim ve kullanım davranışları, IP bilgisi, uygulama içinde alınan hatalar, uygulama kullanım süreleri, coğrafi lokasyonu gibi&nbsp;&nbsp;(Uygulama tarafından otomatik toplanan veriler) bilgileri Magnus ile paylaşmayı kabul eder.'
                }
            },
            {
                id: 'GxcWQV0N9w',
                type: 'paragraph',
                data: {
                    text: '4. Kullanıcı, Magnus’ın bu verileri ek bir onaya gerek kalmaksızın aşağıdaki amaç ve şekillerde kullanabileceğini kabul, beyan ve taahhüt etmektedir:'
                }
            },
            {
                id: '7eCzKSPPFG',
                type: 'list',
                data: {
                    style: 'unordered',
                    items: [
                        'Analiz, bilimsel çalışma ya da istatistiksel çalışmalar',
                        'Magnus, Kullanıcı’nın izlediği ziyaretçi hareket ve tercihlerini analiz ederek yorumlamaktadır. Kişisel bilgiler içermeyen bu istatistiksel veriler, çeşitli promosyon, reklam ve kampanya kurguları, ya da uygulamanın daha yüksek bir kullanıcı memnuniyeti için yeniden düzenlenmesi/ek fonksiyon önerileri alınması amaçlarıyla Şirket’in iş ortaklarıyla paylaşılabilecektir. Kullanıcı, kişisel bilgi ve verilerinin özel bu amaçla Şirket’in iş ortaklığı olan üçüncü şahıslarla paylaşılmasını kabul etmektedir.',
                        'Kullanıcı ek olarak, Magnus’ın Kişisel Veriler ile Uygulama tarafından otomatik toplanan verileri, bülten ve haberleşme bildirimleri göndermek, teknik hataları gidermek, kullanıcıya özel içerik ya da teklif sunmak, kampanya/promosyon/reklam tasarlamak ve sunmak, uygulama için satış gerçekleştirmek, uygulama tasarım ve fonksiyonlarını geliştirmek üzere kullanacağını kabul etmektedir.'
                    ]
                }
            },
            {
                id: 'qmiftf6F2N',
                type: 'paragraph',
                data: {
                    text: '5. Kullanıcı’nın kişisel verilerin korunmasına ilişkin hakları aşağıda belirtilmiştir:'
                }
            },
            {
                id: 'ttyp7HEh3A',
                type: 'list',
                data: {
                    style: 'unordered',
                    items: [
                        'Kişisel verilerinin işlenip işlenmediğini öğrenmek,',
                        'Kişisel verileri işlenmişse buna ilişkin bilgi talep etmek,',
                        'Kişisel verilerinin hangi amaçla işlendiğini ve bu amaçlara uygun kullanılıp kullanılmadığını öğrenmek,',
                        'Kişisel verilerinin yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri bilmek,',
                        'Kişisel verilerinin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini istemek,',
                        'Kişisel verilerinin silinmesini veya yok edilmesini talep etmek,',
                        'Düzeltme, silme ve yok etme taleplerinin kişisel verilerinin aktarıldığı üçüncü kişilere bildirilmesini istemek, işlenen verilerinin münhasıran otomatik sistemler vasıstasıyla analiz edilmesi suretiyle şahsı aleyhine bir sonucun ortaya çıkmasına itiraz etmek,',
                        'Kişisel verilerinin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde zararın giderilmesini talep etmek.'
                    ]
                }
            },
            {
                id: 'VkT9Gz1gVw',
                type: 'paragraph',
                data: {
                    text: 'Kullanıcı, kişisel verilerinin korunmasına ilişkin taleplerini Magnus’ın info@Magnus.me adresine iletmelidir. Talepler, titizlikle değerlendirilecek ve Kullanıcı ile en kısa zamanda bir irtibat kurulacaktır.'
                }
            },
            {
                id: 'QC3mC8UtWU',
                type: 'paragraph',
                data: {
                    text: 'Kullanıcılının Magnus’ın sisteminde tutulan verilerinin kopyasını, talep etmesi halinde, talep edilen veriler, gerekli güvenlik kontrolü yapıldıktan sonra Kullanıcı ile paylaşılacaktır.'
                }
            },
            {
                id: 'KOtTknbB0z',
                type: 'paragraph',
                data: {
                    text: '6. Uygulama’nın daha hızlı ve etkin bir şekilde kullanılabilmesi için oturum cookie (çerez)’leri Kullancı’nın cihazında saklanmaktadır. Kullanıcı dilerse, bu bilgilerin tutulmasını cihaz ayarlarında yapacağı değişiklik ile engelleyebilecektir. Ancak bu durumda Kullanıcı Uygulama’nın tüm fonksiyonlarından yararlanamayabilir.'
                }
            },
            {
                id: 'DkPLIruK8K',
                type: 'paragraph',
                data: {
                    text: '7. Kullanıcı bilgileri, Kullanıcı’nın Uygulama’yı silmesini veya üyeliğini sona erdirmesini takip eden 3 (üç) yıl süre ile Şirket veri tabanında saklanacaktır.'
                }
            },
            {
                id: '7Su7pl2I9n',
                type: 'paragraph',
                data: {
                    text: '8. Kullanıcılar, Magnus’ın Üçüncü Şahıslarla (örneğin iş ortakları; teknik, ödeme ve dağıtım hizmeti taşeronları, reklam ağları, analiz sağlayıcılar, arama bilgisi sağlayıcılar, kredi referans ajansları) kurduğu ilişkiler aracılığıyla da haklarında bilgi (“Başka Kaynaklardan Edinilen Bilgiler”) edinebileceğini kabul ederler.'
                }
            },
            {
                id: '136fePcKMK',
                type: 'paragraph',
                data: {
                    text: '9. Kullanıcı, Magnus tarafından işbu sözleşmede belirlenen amaçlarla bilgilerinin üçüncü kişilerle paylaşılmasına muvafakat göstermektedir. Ek olarak kullanıcı aşağıdaki durumlarda da bilgilerinin kullanılabileceğine muvafakat gösterir.'
                }
            },
            {
                id: 'Em99kZcVMU',
                type: 'list',
                data: {
                    style: 'unordered',
                    items: [
                        'Magnus’ın hizmetlerini sağlamak veya ulaştırmak için üçüncü bir hizmet sağlayıcıya ihtiyaç duyması;',
                        'Yasal ya da mevzuat zorunlulukları, güvenlik ihtiyaçları',
                        'İşletme devri/satışı',
                        'Magnus’ın hak ve mülkiyetinin, diğer kullanıcıların ve üçüncü şahısların hak ve mülkiyetlerinin korunması'
                    ]
                }
            },
            {
                id: 'vzBCCnwVy3',
                type: 'paragraph',
                data: {
                    text: '10. Kullanıcı, kişisel verilerinin Gizlilik Anlaşması ve Aydınlatma Metninde gösterilen şekillerde işlenmesine, yurtiçi ve yurtdışına aktarılmasına açık rıza göstermektedir.'
                }
            },
            {
                id: 'jKBEPJ0Njg',
                type: 'paragraph',
                data: {
                    text: '11. Uygulama ya da internet Sitesi üzerinden başka site ve uygulamalara link verilmesi mümkün olup, Magnus bu site ve uygulamaların gizlilik uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır.'
                }
            },
            {
                id: 'ju1AgQWAc_',
                type: 'paragraph',
                data: {
                    text: '12. Sözleşme süresince ve Sözleşme sona erse dahi sonrasında, Magnus ve/veya iştirakleri ve/veya birlikte iş yaptığı 3. şahıslar Kullanıcı’nın verdiği işbu onay kapsamında Kullanıcı’ya pazarlama ve reklam amaçlı elektronik posta, kısa mesaj, bildirim gibi iletiler gönderebilecektir. Kullanıcı her zaman bu konuda verdiği onayı geri alma hakkına sahip olup bu konuda info@Magnus.me elektronik posta adresine e-posta ile talebini iletebilir.'
                }
            },
            {
                id: 'phweciiAi6',
                type: 'paragraph',
                data: {
                    text: '13. Magnus, Gizlilik Politikası üzerinde zaman zaman değişiklik yapmak hakkını saklı tutmaktadır. Herhangi bir değişiklik durumunda bununla ilgili bilgi Kullanıcı’ya iletilecektir.'
                }
            }
        ],
        version: '2.24.3'
    }
};

export default privacyPolicy;
