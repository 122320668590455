import React, { useState } from 'react';
import './LanguageSwitch.scss';
import { useLanguage } from '../../store/store';
import ArrowIcon from '../../assets/svg/Vector.svg';
import { AnimatePresence, motion } from 'framer-motion';

export interface LanguageSwitchProps {
    label?: string;
}

const LanguageSwitch = ({ label }: LanguageSwitchProps) => {
    const { selectedLanguage, setLanguage } = useLanguage();
    const [isListOpen, setIsListOpen] = useState<boolean>(false);

    return (
        <div className="LanguageSwitch" data-testid="LanguageSwitch">
            <div onClick={() => setIsListOpen(!isListOpen)} className="LanguageSwitch__main">
                <div>{selectedLanguage.toUpperCase()}</div>
                <img className={isListOpen ? 'arrow open' : 'arrow'} src={ArrowIcon} alt="arrow" />
            </div>
            <AnimatePresence key="language">
                {isListOpen && (
                    <motion.div
                        initial={{ height: 0, fontSize: 0, padding: 0 }}
                        animate={{ height: '100%', fontSize: '0.85rem', padding: '0.75rem' }}
                        transition={{ duration: 0.3 }}
                        exit={{ height: 0, fontSize: 0, padding: 0 }}
                        className="LanguageSwitch__list"
                    >
                        <span
                            onClick={() => {
                                setLanguage('en');
                                setIsListOpen(false);
                            }}
                        >
                            EN
                        </span>
                        <span
                            onClick={() => {
                                setLanguage('tr');
                                setIsListOpen(false);
                            }}
                        >
                            TR
                        </span>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default LanguageSwitch;
