import React from 'react';

export const setHeader = (lang: string) => {
    switch (lang) {
        case 'en':
            return <span>Display the earthquake analysis of your district. </span>;
        case 'tr':
            return <span>Mahallenizin deprem konum analizini görüntüleyin.</span>;
        default:
            break;
    }
};

export const setParag = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <span>
                    View the breakdown by population and gathering area density, expected number of
                    buildings with heavy damage and closest hospital infromation.
                </span>
            );
        case 'tr':
            return (
                <span>
                    Nüfus ve toplanma alan yoğunluğu, beklenen ağır hasarlı bina sayısı ve en yakın
                    hastane bilgilerine anında ulaşın.
                </span>
            );
        default:
            break;
    }
};
