import React, { useEffect } from 'react';
import './About.scss';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { pageTransitions, pageVariants } from '../../utils/utils';
import { setBrandText, setList, setSubTitle, setTitle, subTitle } from './utils';
import { useLanguage } from '../../store/store';
import EllipseLeft from '../../assets/png/EllipseLeft.png';
import EllipseRight from '../../assets/png/EllipseRight.png';
import Brand from '../../assets/svg/Brand.svg';
import Footer from '../../components/Footer/Footer';

const About = () => {
    const location = useLocation();
    const { selectedLanguage } = useLanguage();
    useEffect(() => {
        console.log('About is loaded');
        return () => {
            console.log('About is unloaded');
        };
    }, [location]);

    return (
        <motion.div
            transition={pageTransitions}
            variants={pageVariants}
            animate="enter"
            exit="exit"
            initial="initial"
            className="About"
            data-testid="About"
        >
            <div className="About__frame">
                <div className="About__frame__main">
                    <div className="About__frame__main__title">{setTitle(selectedLanguage)}</div>
                    <div className="About__frame__main__parag">{subTitle(selectedLanguage)}</div>
                    <div className="About__frame__main__list">
                        <ul className="About__frame__main__list__con">
                            {setList(selectedLanguage).map((item, ind) => {
                                return (
                                    <li key={ind}>
                                        <span className="circle"></span>
                                        <span>{item}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="About__frame__main__subtitle">
                        {setSubTitle(selectedLanguage)}
                    </div>
                </div>
                <div className="About__frame__brand">
                    <div className="About__frame__brand__text">
                        {setBrandText(selectedLanguage)}
                    </div>
                    <div className="About__frame__brand__logo">
                        <img src={Brand} alt="wunder"></img>
                    </div>
                </div>
                <img className="ellipeleft" src={EllipseLeft} alt="" />
                <img className="ellipseright" src={EllipseRight} alt="" />
            </div>
            <Footer></Footer>
        </motion.div>
    );
};

export default About;
