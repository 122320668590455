export const pageVariants = {
    enter: { y: 0, opacity: 1 },
    exit: { y: 200, opacity: 0 },
    initial: { y: 0, opacity: 0 }
};

export const pageTransitions = {
    duration: 1,
    type: 'tween',
    ease: 'anticipate'
};
