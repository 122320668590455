import React from 'react';
import { TLanguageOption } from '../../store/store';

export const setTitle = (lang: string) => {
    switch (lang) {
        case 'tr':
            return (
                <div className="DownloadPage__frame__content__title">
                    <h2>Magnus ile depreme karşı </h2>
                    <h2>önleminizi alın.</h2>
                </div>
            );
        case 'en':
            return (
                <div className="DownloadPage__frame__content__title">
                    <h2>Take precautions against </h2>
                    <h2>earthquakes with Magnus.</h2>
                </div>
            );
        default:
            break;
    }
};
export const setText = (lang: string) => {
    switch (lang) {
        case 'tr':
            return (
                <div className="DownloadPage__frame__content__links__store__info">
                    <p>İndirmek için QR kodu </p>
                    <p>telefonunuza okutunuz</p>
                </div>
            );
        case 'en':
            return (
                <div className="DownloadPage__frame__content__links__store__info">
                    <p>Download mobile app by</p>
                    <p>scanning the QR code</p>
                </div>
            );
        default:
            break;
    }
};
