import React from 'react';
import logo from './logo.svg';
import './App.css';
import NavigationBar from './components/NavigationBar/NavigationBar';
import HomePage from './pages/HomePage/HomePage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './pages/About/About';
import { AnimatePresence } from 'framer-motion';
import DownloadPage from './pages/DownloadPage/DownloadPage';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
function App() {
    return (
        <div className="App">
            <AnimatePresence>
                <BrowserRouter>
                    <NavigationBar></NavigationBar>
                    <Routes>
                        <Route path="/" element={<HomePage />}></Route>
                        <Route path="/about" element={<About />}></Route>
                        <Route path="/download" element={<DownloadPage />}></Route>
                        <Route path="/privacy" element={<PrivacyPolicy />}></Route>
                    </Routes>
                </BrowserRouter>
            </AnimatePresence>
        </div>
    );
}

export default App;
