import React from 'react';

export const setHome = (lang: string) => {
    switch (lang) {
        case 'tr':
            return 'Anasayfa';
        case 'en':
            return 'Home';
        default:
            return 'Home';
    }
};
export const setAbout = (lang: string) => {
    switch (lang) {
        case 'tr':
            return 'Hakkımızda';
        case 'en':
            return 'About';
        default:
            return 'About';
    }
};
export const setContact = (lang: string) => {
    switch (lang) {
        case 'tr':
            return 'İletişim';
        case 'en':
            return 'Contact';
        default:
            return 'Contact';
    }
};

export const setDownload = (lang: string) => {
    switch (lang) {
        case 'en':
            return 'Download';
        case 'tr':
            return 'İndir';
        default:
            return 'Download';
    }
};
