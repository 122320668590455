import React, { useEffect, useRef } from 'react';
import './LottieElement.scss';
import Lottie from 'lottie-web';

export interface LottieElementProps {
    lottieData: any;
    bgImage: string;
    index: number;
}

const LottieElement = ({ lottieData, bgImage, index }: LottieElementProps) => {
    const lottieRef = useRef<HTMLDivElement>(null);

    const setClass = (index: number) => {
        switch (index) {
            case 0:
                return 'areas-lottie';
            case 1:
                return 'earthquake-lottie';
            case 2:
                return 'prepList-lottie';
            default:
                return '';
        }
    };

    useEffect(() => {
        if (lottieRef.current) {
            Lottie.loadAnimation({
                container: lottieRef.current,
                animationData: lottieData,
                loop: true,
                autoplay: true
            });
        }
    }, [lottieRef, lottieData]);

    return (
        <div
            style={{ backgroundImage: `url(${bgImage})` }}
            className="LottieElement"
            data-testid="LottieElement"
        >
            <div className={setClass(index)} ref={lottieRef}></div>
        </div>
    );
};

export default LottieElement;
