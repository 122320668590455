import React, { useEffect } from 'react';
import './HomePage.scss';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import Hero from '../../components/Hero/Hero';
import ImageAndContent from '../../components/ImageAndContent/ImageAndContent';
import Footer from '../../components/Footer/Footer';
import { pageTransitions, pageVariants } from '../../utils/utils';
import { motion } from 'framer-motion';

const HomePage = () => {
    useEffect(() => {
        console.log('HomePage is loaded');
        return () => {
            console.log('HomePage is unloaded');
        };
    }, [location]);

    return (
        <div className="HomePage" data-testid="HomePage">
            <motion.div
                transition={pageTransitions}
                variants={pageVariants}
                animate="enter"
                exit="exit"
                initial="initial"
                className="HomePage"
                data-testid="HomePage"
            >
                <div className="relative bg-white">
                    <div className="flex w-full flex-col">
                        <NavigationBar></NavigationBar>
                        <Hero></Hero>
                        <ImageAndContent></ImageAndContent>
                        <Footer></Footer>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default HomePage;
