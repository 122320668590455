import React from 'react';
import './StoreButton.scss';
import Apple from '../../assets/svg/apple.svg';
import Google from '../../assets/svg/google.svg';

const StoreButton = () => {
    return (
        <div className="StoreButton" data-testid="StoreButton">
            <div
                onClick={() => {
                    window.location.replace('https://apps.apple.com/us/app/magnus/id1488116106');
                }}
            >
                <img src={Apple} alt="appleStoreButonu" />
            </div>
            <div
                onClick={() => {
                    window.location.replace(
                        'https://play.google.com/store/apps/details?id=is.wunder.quakeguard'
                    );
                }}
            >
                <img src={Google} alt="googleStoreButonu" />
            </div>
        </div>
    );
};

export default StoreButton;
