import React, { useEffect, useState } from 'react';
import './NavigationBar.scss';

import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/svg/Logo.svg';
import HamburgerIcon from '../../assets/svg/Top.svg';
import CrossIcon from '../../assets/svg/cross.svg';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import { AnimatePresence, motion } from 'framer-motion';
import StoreButton from '../StoreButton/StoreButton';
import { useLanguage } from '../../store/store';
import { setAbout, setContact, setDownload, setHome } from './utils';

export interface NavigationBarProps {
    label?: string;
}

const NavigationBar = ({ label }: NavigationBarProps) => {
    const { selectedLanguage, setLanguage } = useLanguage();
    const [isSlideOpen, setIsSlideOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<string>('home');
    const [path, setPath] = useState<'/about' | '/' | '/download' | '/contact'>('/');
    const [checked, setChecked] = useState(false);
    const location = useLocation();
    const variants = {
        open: { opacity: 1, transition: { duration: 0.5 } },
        closed: { opacity: 0, transition: { duration: 0.5 } }
    };
    useEffect(() => {
        switch (location.pathname) {
            case '/':
                return setPath('/');
            case '/about':
                return setPath('/about');
            case '/contact':
                return setPath('/contact');
            case '/download':
                return setPath('/download');
            default:
                break;
        }
    }, [location]);
    return (
        <div className="NavigationBar" data-testid="NavigationBar">
            <div className="NavigationBar__logo">
                <Link to="/">
                    <img src={Logo} alt="logo" />
                </Link>
            </div>
            <div className="NavigationBar__links">
                <ul>
                    <li className={path === '/' ? 'selected-item link' : 'link'}>
                        <Link to="/" onClick={() => setSelectedItem('about')}>
                            {setHome(selectedLanguage)}
                        </Link>
                    </li>
                    <li className={path === '/about' ? 'selected-item link' : ''}>
                        <Link to="/about" onClick={() => setSelectedItem('about')}>
                            {setAbout(selectedLanguage)}
                        </Link>
                    </li>
                    <li className={path === '/contact' ? 'selected-item link' : ''}>
                        <div onClick={() => setSelectedItem('contact')}>
                            <a href="mailto:info@magnus.systems">{setContact(selectedLanguage)}</a>
                        </div>
                    </li>
                    <li className="download">
                        <Link onClick={() => setSelectedItem('download')} to="/download">
                            {setDownload(selectedLanguage)}
                        </Link>
                    </li>
                </ul>
                <div className="Navigation__language">
                    <LanguageSwitch></LanguageSwitch>
                </div>
            </div>
            <div onClick={() => setIsSlideOpen(!isSlideOpen)} className="NavigationBar__menu">
                <AnimatePresence key="menuopen">
                    <motion.img
                        animate={isSlideOpen ? 'open' : 'closed'}
                        variants={variants}
                        src={CrossIcon}
                        alt="close"
                        className="close-icon"
                    />
                    <motion.img
                        animate={isSlideOpen ? 'closed' : 'open'}
                        variants={variants}
                        src={HamburgerIcon}
                        alt="menu"
                        className="hamburger-icon"
                    />
                </AnimatePresence>
            </div>
            <AnimatePresence key="bottommenu">
                {isSlideOpen && (
                    <motion.div
                        animate={{ x: 0 }}
                        initial={{ x: '100%' }}
                        exit={{
                            x: '100%',
                            transition: {
                                duration: 0.5
                            }
                        }}
                        transition={{ duration: 0.5 }}
                        className={`NavigationBar__side ${isSlideOpen ? 'open' : ''}`}
                    >
                        <div className="links">
                            <div
                                className="link"
                                onClick={() => {
                                    setIsSlideOpen(false);
                                }}
                            >
                                <Link to="/">{setHome(selectedLanguage)}</Link>
                            </div>
                            <div
                                className="link"
                                onClick={() => {
                                    setIsSlideOpen(false);
                                }}
                            >
                                <Link className="link" to="/about">
                                    {setAbout(selectedLanguage)}
                                </Link>
                            </div>
                            <div className="link" onClick={() => setSelectedItem('contact')}>
                                <a href="mailto:info@magnus.systems">
                                    {setContact(selectedLanguage)}
                                </a>
                            </div>
                            <Link
                                to="/download"
                                className="link download"
                                onClick={() => setSelectedItem('download')}
                            >
                                {setDownload(selectedLanguage)}
                            </Link>
                            <div
                                className="link"
                                onClick={() => {
                                    if (selectedLanguage === 'en') {
                                        setLanguage('tr');
                                    } else {
                                        setLanguage('en');
                                    }
                                    setIsSlideOpen(false);
                                }}
                            >
                                {selectedLanguage === 'en' ? 'TR' : 'EN'}
                            </div>
                        </div>
                        <div className="store-and-language">
                            <StoreButton></StoreButton>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default NavigationBar;
