import React, { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';

interface IAnimatedContentProps {
    title?: string | JSX.Element;
    parag?: string | JSX.Element;
    type: 'parag' | 'title';
}

const AnimatedContent = ({ title, parag, type }: IAnimatedContentProps) => {
    const { ref, inView } = useInView();
    const animation = useAnimation();
    useEffect(() => {
        if (inView) {
            console.log(inView);
            animation.start({
                y: 0,
                opacity: 1,
                transition: {
                    type: 'tween',
                    duration: 0.8,
                    delay: 0.2
                }
            });
        }
        if (!inView) {
            animation.start({
                y: 200,
                opacity: 0,
                transition: {
                    type: 'tween',
                    duration: 0.8
                }
            });
        }
    }, [inView]);
    return (
        <>
            {screen.width >= 1024 ? (
                <>
                    {type === 'title' && title && (
                        <motion.h3 ref={ref} animate={animation}>
                            {title}
                        </motion.h3>
                    )}
                </>
            ) : (
                <>{type === 'title' && title && <h3 ref={ref}>{title}</h3>}</>
            )}
            {screen.width >= 1024 ? (
                <>
                    {type === 'parag' && parag && (
                        <motion.p ref={ref} animate={animation}>
                            {parag}
                        </motion.p>
                    )}
                </>
            ) : (
                <>{type === 'parag' && parag && <p ref={ref}>{parag}</p>}</>
            )}
        </>
    );
};

export default AnimatedContent;
