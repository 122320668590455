import React, { useEffect } from 'react';
import './DownloadPage.scss';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { pageTransitions, pageVariants } from '../../utils/utils';
import useWindowSize from '../../hooks/useWindowSize';
import { useLanguage } from '../../store/store';
import Footer from '../../components/Footer/Footer';
import QR from '../../assets/svg/QR.svg';
import EllipseLeft from '../../assets/png/EllipseLeft.png';
import EllipseRight from '../../assets/png/EllipseRight.png';
import { setTitle } from './utils';
import { setText } from './utils';
import PhonesDownload from '../../assets/png/PhonesDownload.png';
import DirectPage from '../DirectPage/DirectPage';

const DownloadPage = () => {
    const location = useLocation();
    const { width } = useWindowSize();
    const { selectedLanguage } = useLanguage();
    useEffect(() => {
        console.log('DownloadPage is loaded');
        return () => {
            console.log('DownloadPage is unloaded');
        };
    }, [location]);

    return (
        <motion.div
            transition={pageTransitions}
            variants={pageVariants}
            animate="enter"
            exit="exit"
            initial="initial"
            className="DownloadPage"
            data-testid="DownloadPage"
        >
            {width > 0 && width < 1024 && <DirectPage></DirectPage>}
            {width >= 1024 && (
                <div className="DownloadPage__frame">
                    <div className="DownloadPage__frame__content">
                        {setTitle(selectedLanguage)}
                        <div className="DownloadPage__frame__content__links">
                            <div className="DownloadPage__frame__content__links__qr">
                                <img src={QR} alt="QrKod" />
                            </div>
                            <div className="DownloadPage__frame__content__links__store">
                                {setText(selectedLanguage)}
                            </div>
                        </div>
                    </div>
                    <div className="DownloadPage__frame__visual">
                        <img loading="lazy" src={PhonesDownload} alt="" />
                    </div>
                    <img className="ellipeleft" src={EllipseLeft} alt="" />
                    <img className="ellipseright" src={EllipseRight} alt="" />
                </div>
            )}
            {width >= 1024 && <Footer></Footer>}
        </motion.div>
    );
};

export default DownloadPage;
