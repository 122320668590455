import React, { useEffect, useState } from 'react';
import './PrivacyPolicy.scss';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../store/store';
import Blocks, { ConfigProp, DataProp } from 'editorjs-blocks-react-renderer';
import privacyPolicyData from './data';

const ParserConfig: ConfigProp = {
    header: {
        className: 'font-bold'
    }
};

const PrivacyPolicy = () => {
    const location = useLocation();
    const { selectedLanguage } = useLanguage();
    const [selectedData, setselectedData] = useState<DataProp | undefined>(undefined);

    useEffect(() => {
        console.log('PrivacyPolicy is loaded');
        return () => {
            console.log('PrivacyPolicy is unloaded');
        };
    }, [location]);

    useEffect(() => {
        console.log('PrivacyPolicy is loaded', selectedLanguage);
        const _temp: any = privacyPolicyData;
        setselectedData(_temp[selectedLanguage] as DataProp);
    }, [selectedLanguage]);

    return (
        <div className="PrivacyPolicy" data-testid="PrivacyPolicy">
            <div className="relative bg-white">
                <div className="max-w-7xl mx-auto px-6 sm:px-8 pt-28 text-left">
                    {selectedData && <Blocks data={selectedData} config={ParserConfig} />}
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
