import React from 'react';

export const setTitle1 = (lang: string) => {
    switch (lang) {
        case 'en':
            return <span>Find the closest, official post-disaster gathering areas.</span>;
        case 'tr':
            return (
                <span>Yakınınızdaki resmi, deprem sonrası toplanma alanlarını görüntüleyin.</span>
            );
        default:
            return <span></span>;
    }
};
export const setParag1 = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <span>
                    Location of the closest gathering areas are a crucial part of a family’s
                    post-disaster plan. Magnus displays all officially identified areas based on
                    your location.
                </span>
            );
        case 'tr':
            return (
                <span>
                    Güncel ve resmi, deprem sonrası toplanma alanları aile deprem planınızın önemli
                    unsurlarındandır. Magnus ile konumunuza en yakın ve resmi toplanma alanlarını
                    kolaylıkla görüntüleyebilirsiniz.
                </span>
            );
        default:
            return <span></span>;
    }
};
export const setTitle2 = (lang: string) => {
    switch (lang) {
        case 'en':
            return <span>Display earthqaukes on the map and get notified about new ones.</span>;
        case 'tr':
            return <span>Güncel depremlerden haberdar olun ve harita üzerinde görüntüleyin.</span>;
        default:
            return <span></span>;
    }
};
export const setParag2 = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <span>
                    Magnus notifies you about nearby earthquakes in real-time, and displays its
                    exact location on the map. You can also view detailed statistics of the seismic
                    activity in the related area.
                </span>
            );
        case 'tr':
            return (
                <span>
                    Magnus ile yakınınızdaki güncel depremlerden haberdar olabilir, depremleri ve
                    ilgili bölgenin sismik hareketlilik istatistiklerini harita üzerinde
                    görüntüleyebilirsiniz.
                </span>
            );
        default:
            return <span></span>;
    }
};
export const setTitle3 = (lang: string) => {
    switch (lang) {
        case 'en':
            return <span>Complete your earthquake precautions.</span>;
        case 'tr':
            return <span>Deprem hazırlıklarınızı tamamlayın.</span>;
        default:
            return <span></span>;
    }
};
export const setParag3 = (lang: string) => {
    switch (lang) {
        case 'en':
            return (
                <span>
                    Learn about all critical precautions you need to take at home and apply them
                    easily by following video guides.
                </span>
            );
        case 'tr':
            return (
                <span>
                    Magnus ile deprem öncesi almanız gereken tüm önlemleri öğrenin, videolu
                    anlatımları takip ederek kolaylıkla uygulayın.
                </span>
            );
        default:
            return <span></span>;
    }
};
