import React, { useEffect, useRef } from 'react';
import './Hero.scss';
import HeroImage from '../../assets/png/Image 1.png';
import StoreButton from '../StoreButton/StoreButton';
import MouseIcon from '../../assets/svg/Group 37.svg';
import { setHeader, setParag } from './utils';
import { useLanguage } from '../../store/store';
import QR from '../../assets/svg/QR.svg';
import Lottie from 'lottie-web';
import AnalysisLottie from '../../assets/lottie/Analysis.json';

export interface HeroProps {
    label?: string;
}

const Hero = ({ label }: HeroProps) => {
    const { selectedLanguage } = useLanguage();
    console.log(selectedLanguage);
    const lottieRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (lottieRef.current) {
            Lottie.loadAnimation({
                container: lottieRef.current,
                animationData: AnalysisLottie,
                loop: true,
                autoplay: true
            });
        }
    }, [lottieRef]);
    return (
        <div className="Hero" data-testid="Hero">
            <div className="Hero__content">
                <div className="Hero__content__top">
                    <div className="Hero__content__header">{setHeader(selectedLanguage)}</div>
                    <div className="Hero__content__parag">{setParag(selectedLanguage)}</div>
                    <div className="Hero__content__qr">
                        <img src={QR} alt="" />
                    </div>
                    <StoreButton></StoreButton>
                </div>
                <div className="mouse-icon">
                    <img src={MouseIcon} alt="" />
                </div>
            </div>
            <div className="Hero__image">
                <div
                    className="flex w-full  overflow-hidden rounded-3xl Hero__lottie"
                    ref={lottieRef}
                ></div>
            </div>
        </div>
    );
};

export default Hero;
